import axios from '@/apis/axios';
import { getAccessToken, convertCamelToSnake } from '@/lib/api';

/**
 * 営業店一覧取得
 */
export const getSalesList = async (option, condition) => {
  const accessToken = await getAccessToken();
  let params = {};
  if (option) {
    params = Object.assign(params, convertCamelToSnake(option));
  }
  if (condition) {
    params = Object.assign(params, convertCamelToSnake(condition));
  }

  const response = await axios
    .get(`/agencies/sales`, {
      params: { ...params },
      headers: { 'access-token': accessToken },
    })
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};

/**
 * 営業店取得
 */
export const getSales = async salesId => {
  const accessToken = await getAccessToken();

  const response = await axios
    .get(`/agencies/sales/${salesId}`, {
      headers: { 'access-token': accessToken },
    })
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};

/**
 * 営業店登録
 */
export const postSales = async createParam => {
  const accessToken = await getAccessToken();

  const response = await axios
    .post(
      `/agencies/sales`,
      { ...createParam },
      { headers: { 'access-token': accessToken } }
    )
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};

/**
 * 営業店更新
 */
export const putSales = async (salesId, updateParam) => {
  const accessToken = await getAccessToken();

  const response = await axios
    .put(
      `/agencies/sales/${salesId}`,
      { ...updateParam },
      { headers: { 'access-token': accessToken } }
    )
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};

/**
 * 営業店削除
 */
export const deleteSales = async salesId => {
  const accessToken = await getAccessToken();

  const response = await axios
    .delete(`/agencies/sales/${salesId}`, {
      headers: { 'access-token': accessToken },
    })
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};

/**
 * 営業店ID一覧取得
 */
export const getSalesIdList = async () => {
  const accessToken = await getAccessToken();

  const response = await axios
    .get('/agencies/sales/id/list', {
      headers: { 'access-token': accessToken },
    })
    .catch(error => {
      throw error.response.data;
    });
  const list = response.data.map(sales => ({
    // TODO:
    text: sales.salesSectionName,
    value: sales.id,
  }));

  return list;
};

/**
 * 部担コード一覧取得
 */
export const getSalesCodeDetails = async () => {
  const salesData = await getSalesCode();
  let list = [];
  salesData.forEach(function(sales) {
    list[sales.salesCode] = {
      selesId: sales.id,
      salesCode: sales.salesCode,
      salesDepartmentName: sales.salesDepartmentName,
      salesSectionName: sales.salesSectionName,
    };
  });

  return list;
};

/**
 * 部担コード一覧取得
 */
export const getSalesCodeList = async () => {
  const salesData = await getSalesCode();
  let list = [];
  salesData.forEach(function(sales) {
    list.push(sales.salesCode);
  });

  return list;
};

/**
 * 更新対象営業店一覧取得
 */
export const getUpdateSalesList = async () => {
  const accessToken = await getAccessToken();

  const response = await axios
    .get(`/agencies/sales/update/list`, {
      headers: { 'access-token': accessToken },
    })
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};

/**
 * 営業店一括登録
 */
export const postBulkRegisterSales = async salesList => {
  const accessToken = await getAccessToken();

  const form = new FormData();
  form.append('sales', JSON.stringify(salesList));

  const response = await axios
    .post(`/agencies/sales/bulk/register`, form, {
      headers: { 'access-token': accessToken },
    })
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};

/**
 * 営業店一括更新
 */
export const postBulkUpdateSales = async salesList => {
  const accessToken = await getAccessToken();

  const form = new FormData();
  form.append('sales', JSON.stringify(salesList));

  const response = await axios
    .post(`/agencies/sales/bulk/update`, form, {
      headers: { 'access-token': accessToken },
    })
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};

// 部担コード取得
const getSalesCode = async () => {
  const accessToken = await getAccessToken();

  const response = await axios
    .get('/agencies/sales/code/list', {
      headers: { 'access-token': accessToken },
    })
    .catch(error => {
      throw error.response.data;
    });
  return response.data;
};
